<template>
    <div class="container">
        <div class="top_tab">
            <el-tabs v-loading="loading" v-model="tabActiveName" type="card" class="tab_box" @tab-click="handleTabClick">
                <el-tab-pane :label="window.innerWidth > 821 ? 'Possible Customers' : 'Customers'" name="1" class="result_tab">
                    <div class="table_box">
                        <el-table ref="treeTable" class="computer_right_cont_box" :data="parentTable" border style="width: 100%" :key="refresh">
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <noDate />
                            </template>
                            <el-table-column label="Company Name" prop="name,nameEn" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="{ path: ' /company/companysnapshot', query: {id3a:scope.row.aaa_id,companyCountry:scope.row.company_country}}" class="tbl_company" v-show="showTranslate"><img :src="scope.row.flag" />{{ scope.row.name }}</router-link>
                  <router-link :to="{ path: ' /company/companysnapshot', query: {id3a:scope.row.aaa_id,companyCountry:scope.row.company_country}}" class="tbl_company" v-show="!showTranslate"><img :src="scope.row.flag" />{{ scope.row.nameEn }}</router-link> -->
                                    <div @click="setid(scope.row)" class="tbl_company" v-show="showTranslate">
                                        <img :src="scope.row.flag" />
                                        {{ scope.row.name }}
                                    </div>
                                    <div @click="setid(scope.row)" class="tbl_company" v-show="!showTranslate">
                                        <img :src="scope.row.flag" />
                                        {{ scope.row.nameEn }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Country" width="130" prop="country" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                <template slot-scope="scope">
                                    <!-- <span @click="gotocountry1(scope.row)">{{getCountry(scope.row.country)  }}</span> -->
                                    <div style="width: 110px" @click="gotocountry1(scope.row)" class="line_1">{{ getCountry(scope.row.country) }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Location" width="140" prop="location,locationEn" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div style="width: 120px" v-show="showTranslate" class="line_1">{{ scope.row.location }}</div>
                                    <div style="width: 120px" v-show="!showTranslate" class="line_1">{{ scope.row.locationEn }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Inc. Date" width="130" prop="incDate" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Company Type" width="130" prop="companyType" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Revenue" width="160" prop="revenue" align="center" show-overflow-tooltip :resizable="false" />
                        </el-table>
                        <!-- 移动端 -->
                        <div class="phone_page_bg">
                            <div class="table_box">
                                <ul class="phone_table_box">
                                    <li class="phone_table_box_item" v-for="(item, index) in parentTable" :key="index">
                                        <div class="phone_table_box_item_right">
                                            <h1 class="line_1" @click="setid(item)">{{ item.nameEn }}</h1>

                                            <h2 class="line_1">
                                                <span>Country:{{ item.country }}</span>
                                            </h2>
                                            <h2 class="line_1">
                                                <span>Location:{{ item.locationEn }}</span>
                                            </h2>
                                            <h2 class="line_1">Inc. Date: {{ item.incDate }}</h2>
                                            <h2 class="line_1">Company Type: {{ item.companyType }}</h2>
                                            <h2 class="line_1">
                                                <span>Revenue:{{ item.revenue }}</span>
                                            </h2>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                            <div class="search_rsult_txt">{{ page.pageNo }} - {{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="window.innerWidth > 821 ? 'Possible Vendors' : 'Vendors'" name="2" :disabled="loading" class="market_box result_tab">
                    <div class="table_box">
                        <el-table ref="treeTable" class="computer_right_cont_box" :data="parentTable" border style="width: 100%" :key="refresh">
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <noDate />
                            </template>
                            <el-table-column label="Company Name" prop="name,nameEn" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="{ path: ' /company/companysnapshot', query: {id3a:scope.row.aaa_id,companyCountry:scope.row.company_country}}" class="tbl_company" v-show="showTranslate"><img :src="scope.row.flag" />{{ scope.row.name }}</router-link>
                  <router-link :to="{ path: ' /company/companysnapshot', query: {id3a:scope.row.aaa_id,companyCountry:scope.row.company_country}}" class="tbl_company" v-show="!showTranslate"><img :src="scope.row.flag" />{{ scope.row.nameEn }}</router-link> -->
                                    <div class="line_1" @click="setid(scope.row)" v-show="showTranslate">
                                        <img :src="scope.row.flag" />
                                        {{ scope.row.name }}
                                    </div>
                                    <div class="line_1" @click="setid(scope.row)" v-show="!showTranslate">
                                        <img :src="scope.row.flag" />
                                        {{ scope.row.nameEn }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Country" width="130" prop="country" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                <template slot-scope="scope">
                                    <div style="width: 110px" @click="gotocountry1(scope.row)" class="line_1">{{ getCountry(scope.row.country) }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Location" width="140" prop="location,locationEn" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div style="width: 120px" v-show="showTranslate" class="line_1">{{ scope.row.location }}</div>
                                    <div style="width: 120px" v-show="!showTranslate" class="line_1">{{ scope.row.locationEn }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Inc. Date" width="130" prop="incDate" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Company Type" width="130" prop="companyType" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Revenue" width="160" prop="revenue" align="center" show-overflow-tooltip :resizable="false" />
                        </el-table>
                        <div class="phone_page_bg">
                            <div class="table_box">
                                <ul class="phone_table_box">
                                    <li class="phone_table_box_item" v-for="(item, index) in parentTable" :key="index">
                                        <div class="phone_table_box_item_right">
                                            <h1 class="line_1" @click="setid(item)">{{ item.nameEn }}</h1>

                                            <h2 class="line_1">
                                                <span>Country:{{ item.country }}</span>
                                            </h2>
                                            <h2 class="line_1">
                                                <span>Location:{{ item.locationEn }}</span>
                                            </h2>
                                            <h2 class="line_1">Inc. Date: {{ item.incDate }}</h2>
                                            <h2 class="line_1">Company Type: {{ item.companyType }}</h2>
                                            <h2 class="line_1">
                                                <span>Revenue:{{ item.revenue }}</span>
                                            </h2>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                            <div class="search_rsult_txt">{{ page.pageNo }} - {{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="window.innerWidth > 821 ? 'Possible Competiors' : 'Competiors'" name="3" :disabled="loading" class="market_box result_tab">
                    <div class="table_box">
                        <el-table ref="treeTable" class="computer_right_cont_box" :data="parentTable" border style="width: 100%" :key="refresh">
                            <!-- 空数据状态的插槽 -->
                            <template slot="empty">
                                <noDate />
                            </template>
                            <el-table-column label="Company Name" prop="name,nameEn" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link">
                                <template slot-scope="scope">
                                    <!-- <router-link :to="{ path: ' /company/companysnapshot', query: {id3a:scope.row.aaa_id,companyCountry:scope.row.company_country}}" class="tbl_company" v-show="showTranslate"><img :src="scope.row.flag" />{{ scope.row.name }}</router-link>
                  <router-link :to="{ path: ' /company/companysnapshot', query: {id3a:scope.row.aaa_id,companyCountry:scope.row.company_country}}" class="tbl_company" v-show="!showTranslate"><img :src="scope.row.flag" />{{ scope.row.nameEn }}</router-link> -->
                                    <div @click="setid(scope.row)" class="tbl_company" v-show="showTranslate">
                                        <img :src="scope.row.flag" />
                                        {{ scope.row.name }}
                                    </div>
                                    <div @click="setid(scope.row)" class="tbl_company" v-show="!showTranslate">
                                        <img :src="scope.row.flag" />
                                        {{ scope.row.nameEn }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Country" width="120" prop="country" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                <template slot-scope="scope">
                                    <!-- <div style="width: 100px;" v-show="true"  class="line_1">{{}}11</div> -->
                                    <div style="width: 100px" @click="gotocountry1(scope.row)" class="line_1">{{ getCountry(scope.row.country) }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Location" width="140" prop="location,locationEn" align="left" show-overflow-tooltip :resizable="false">
                                <template slot-scope="scope">
                                    <div style="width: 120px" v-show="showTranslate" class="line_1">{{ scope.row.location }}</div>
                                    <div style="width: 120px" v-show="!showTranslate" class="line_1">{{ scope.row.locationEn }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Inc. Date" width="130" prop="incDate" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Company Type" width="130" prop="companyType" align="left" show-overflow-tooltip :resizable="false" />
                            <el-table-column label="Revenue" prop="revenue" width="160" align="center" show-overflow-tooltip :resizable="false" />
                        </el-table>
                        <div class="phone_page_bg">
                            <div class="table_box">
                                <ul class="phone_table_box">
                                    <li class="phone_table_box_item" v-for="(item, index) in parentTable" :key="index">
                                        <div class="phone_table_box_item_right">
                                            <h1 class="line_1" @click="setid(item)">{{ item.nameEn }}</h1>

                                            <h2 class="line_1">
                                                <span>Country:{{ item.country }}</span>
                                            </h2>
                                            <h2 class="line_1">
                                                <span>Location:{{ item.locationEn }}</span>
                                            </h2>
                                            <h2 class="line_1">Inc. Date: {{ item.incDate }}</h2>
                                            <h2 class="line_1">Company Type: {{ item.companyType }}</h2>
                                            <h2 class="line_1">
                                                <span>Revenue:{{ item.revenue }}</span>
                                            </h2>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="page_i_box">
                            <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                            <div class="search_rsult_txt">{{ page.pageNo }} - {{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import { getSimilarCompanies } from '@/api/companyApi.js';
import crypto from '@/utils/crypto';
import Page from '@/components/page';
import noDate from '@/components/tableNoDate';
import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    components: { noDate, Page },
    data() {
        return {
            loading: true,
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            // searchName: '',
            refresh: 0,
            // showTranslate: false,
            tabActiveName: '1', //父级tab
            //表
            parentTable: [],
            // type:1,
            addDialog: false, //添加列表弹框
            index: -1,
            addList: [],
            addPopIndex: false,
            fromPage: 'company',
            collecObj: {},
            window: window,
        };
    },
    computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return this.$parent.powerToPage;
            },
            set() {},
        },
    },
    created() {
        this.getSimilar(1);
    },
    methods: {
        gotocountry1(row) {
            //  if(!row.countryCode.includes(';')){
            let routeData = this.$router.resolve({
                path: '/country?' + '&companyCountry=' + row.country,
            });

            window.open(routeData.href, '_blank');
            //  }
        },
        async getSimilar(type) {
            // console.log('type',type);
            this.loading = true;
            // this.searchName = this.$route.query.searchname ? this.$route.query.searchname : ''
            // const comany = JSON.parse(sessionStorage.getItem('company'))
            const base = JSON.parse(sessionStorage.getItem('base'));
            let params = {
                aaaId: this.$route.query.id3a,
                countryCode: this.$route.query.companyCountry,
                page: this.page.pageNo,
                pageSize: this.page.pageSize,
                operatCode: base.base.operatCode,
                entclasscode: base.base.entclasscode,
                isic: base.current.isicCode,
                type: type,
            };

            await getSimilarCompanies('aaaId=' + params.aaaId + '&countryCode=' + params.countryCode + '&page=' + params.page + '&pageSize=' + params.pageSize + '&operatCode=' + params.operatCode + '&entclasscode=' + params.entclasscode + '&isic=' + params.isic + '&type=' + type).then(result => {
                let data = JSON.parse(crypto.decrypt(result));
                console.log('result- getSimilarList', data);
                this.parentTable = [];
                this.page = {
                    pageNo: 1,
                    pageSize: 10,
                    total: 0,
                };
                if (data && data.code && data.data.similarCompany) {
                    this.page.total = data.data.total;
                    let list = (this.parentTable = data.data.similarCompany);
                    //查找本地图片
                    if (list.length > 0) {
                        list = list.map((item, index) => {
                            let countryImg = this.getCountryImgUrl(item.country);
                            let flag = require('../../../static' + countryImg);
                            item.flag = flag;
                            this.refresh++;
                        });
                    }
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            });
        },
        setid(row) {
            // this.$emit('updateSubLink',0)
            sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.aaaId + '&companyCountry=' + row.country,
            });
            window.open(routeData.href, '_blank');
        },
        //分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.getSimilar(this.tabActiveName);
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.getSimilar(this.tabActiveName);
        },
        //tab切换
        handleTabClick() {
            this.getSimilar(this.tabActiveName);
        },
    },
};
</script>

<style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.top_tab {
    margin-top: 60px;
}

.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}

.el-tabs--card > .el-tabs__header {
    border: none;
}
.tab_box /deep/ .el-table .cell {
    padding-left: 5px;
}
.tab_box /deep/ .el-tabs__header,
.tab_box /deep/.el-tabs__content {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.tab_box /deep/ .el-tabs__header {
    padding: 11px 75px;
}

.result_tab {
    padding: 20px;
}

.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 34px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}

.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 4px 75px;
    display: flex;
    justify-content: space-between;
}

.tab_box /deep/ .el-tabs__item {
    padding: 0 15px;
    width: 160px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    color: #8497ab;
    border: none;
    text-align: center;
}

.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}

.tab_box /deep/ .el-tabs__item.is-active {
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-family: 'Arial Bold';
    color: #ffffff;
}

/* 弹框 */

.add_list {
    margin-bottom: 20px;
}

.add_item {
    display: flex;
    padding: 10px 9px 8px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
}

.add_item:hover {
    background: #e7eaed;
}

.add_item.on {
    background: #1290c9;
}

.add_item.on .add_des_txt,
.add_item.on .add_des_title {
    color: #ffffff;
}

.add_img {
    height: 42px;
}

.add_img img {
    width: 38px;
    height: 42px;
    margin-right: 9px;
}

.add_des_title {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    line-height: 20px;
}

.add_des_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}

.btn_return,
.btn_create {
    width: 180px;
    height: 40px;
    line-height: 40px;
    background: #e7eaed;
    border-radius: 12px;
    font-size: 16px;
    color: #022955;
}

.btn_create {
    width: 180px;
}

.btn_return {
    width: 140px;
}

.create_item_lab {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    margin-bottom: 8px;
}

.create_item_num {
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #8497ab;
}

.create_inpt {
    width: 100%;
    height: 46px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
}
@media (max-width: 821px) {
    .phone_table_box_item {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        padding: 20px 0;
        border-bottom: 1px solid #e0dce5;
    }
    .table_box .phone_table_box_item img {
        width: 28px;
        height: 17px;
        flex: none;
        margin-right: 4px;
        box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right {
        font-size: 14px;
        margin-left: 20px;
        width: 85%;
    }
    .phone_table_box_item_right h1 {
        font-size: 14px;
        font-weight: normal;
    }
    .phone_table_box_item_right h2 {
        font-size: 12px;
        color: #656373;
        font-weight: normal;
        line-height: 22px;
    }
    .top_tab {
        margin-top: 20px !important;
    }
    .tab_box {
        margin-bottom: 10px;
        width: 100%;
        padding: 10px;
        /* padding: 20px; */
        box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
        border-radius: 14px;
        background: #ffffff;
    }
    .tab_box /deep/ .el-tabs__header {
        padding: 11px 5px !important;
    }
    .tab_box /deep/ .el-tabs__nav {
        padding: 4px 5px !important;
    }
    .phone_table_box_item_right .line_1 {
        white-space: nowrap !important;
    }
}
</style>
